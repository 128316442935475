import * as React from 'react';
import { FC } from 'react';
import {
    Edit,
    TextInput,
    Toolbar,
    SaveButton,
    DeleteButton,
    required,
    DateInput,
    SelectInput,
    NumberInput,
    usePermissions,
    useDataProvider,
    TabbedForm,
    FormTab,
} from 'react-admin';

import { Box, Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Aside from './Aside';
import PackImagesGrid from './PackImagesGrid';
import PackJustificatifGrid from './PackJustificatifGrid';
import FullNameField from './FullNameField';
// import ArchivePackButton from './buttons/ArchivePackButton';
// import { validatePasswords } from './PackCreate';
import { Customer, FieldProps } from '../types';
import regions from '../data/regions';
import natures from '../data/natures';
import justificatifStatus from '../data/justificatifStatus';

export const styles = {
    card: { width: '100%' },
    price: { width: '7em' },
    width: { width: '7em' },
    height: { width: '7em' },
    stock: { width: '7em' },
    widthFormGroup: { display: 'inline-block' },
    heightFormGroup: { display: 'inline-block', marginLeft: 32 },
};

const useStyles = makeStyles(styles);

const PackEdit = (props: any) => {
    return (
        <Edit
            title={<PackTitle />}
            aside={<Aside />}
            undoable={false}
            component="div"
            {...props}
        >
            <PackForm />
        </Edit>
    );
};

const PackTitle: FC<FieldProps<Customer>> = ({ record }) =>
    record ? <FullNameField record={record} size="32" /> : null;


const PackForm = (props: any) => {
    const classes = useStyles();
    const { permissions } = usePermissions();

    const dataProvider = useDataProvider();

    if (!props || !props.record) {
        return null;
    }

    const initialBl = props.record.bl;
    const initialBl1 = props.record.bl1;
    const initialBl2 = props.record.bl2;
    const initialBl3 = props.record.bl3;

    const blValidation = async (value, allValues) => {
        try {
            if (initialBl === value) {
                return undefined;
            }

            const { data } = await dataProvider.create('packs/bl', {
                data: { bl: value },
            });
            return undefined;
        } catch (e) {
            console.error(e.message || e);
            return e.message || e;
        }
    };

    const bl1Validation = async (value, allValues) => {
        try {
            if (initialBl1 === value) {
                return undefined;
            }

            const { data } = await dataProvider.create('packs/bl', {
                data: { bl: value },
            });
            return undefined;
        } catch (e) {
            console.error(e.message || e);
            return e.message || e;
        }
    };
    const bl2Validation = async (value, allValues) => {
        try {
            if (initialBl2 === value) {
                return undefined;
            }

            const { data } = await dataProvider.create('packs/bl', {
                data: { bl: value },
            });
            return undefined;
        } catch (e) {
            console.error(e.message || e);
            return e.message || e;
        }
    };
    const bl3Validation = async (value, allValues) => {
        try {
            if (initialBl3 === value) {
                return undefined;
            }

            const { data } = await dataProvider.create('packs/bl', {
                data: { bl: value },
            });
            return undefined;
        } catch (e) {
            console.error(e.message || e);
            return e.message || e;
        }
    };
    const validateBl = [blValidation];
    const validateBl1 = [bl1Validation];
    const validateBl2 = [bl2Validation];
    const validateBl3 = [bl3Validation];

    const CustomToolbar = props => {
        if (permissions !== 'admin') {
            return null;
        }
        return (
            <Toolbar {...props}>
                <SaveButton {...props} />
                <DeleteButton {...props} />
                {/* <ArchivePackButton {...props} /> */}
            </Toolbar>
        );
    };

    return (
        <Card>
            <TabbedForm {...props} toolbar={<CustomToolbar />}>
                <FormTab label="Details">
                    <React.Fragment>
                        <Box display={{ md: 'block', lg: 'flex' }}>
                            <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                {/* <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.packs.fieldGroups.image'
                                        )}
                                    </Typography> */}
                                <Box
                                    display={{
                                        xs: 'block',
                                        sm: 'flex',
                                    }}
                                >
                                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                        <PackImagesGrid
                                            record={props.record}
                                            label="resources.posts.fields.images"
                                        />
                                    </Box>
                                </Box>

                                {/* number


date */}
                                <TextInput
                                    source="pharmacy"
                                    label="resources.packs.fields.pharmacy"
                                    resource="packs"
                                    // validate={requiredValidate}

                                    fullWidth
                                />

                                <Box
                                    display={{
                                        xs: 'block',
                                        sm: 'flex',
                                    }}
                                >
                                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                        <SelectInput
                                            source="region"
                                            label="resources.packs.fields.region"
                                            resource="packs"
                                            choices={regions}
                                            optionText="name"
                                            optionValue="id"
                                            fullWidth
                                        />
                                    </Box>
                                    <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                        <TextInput
                                            type="city"
                                            source="city"
                                            label="resources.packs.fields.city"
                                            resource="packs"
                                            // validate={requiredValidate}
                                            fullWidth
                                        />
                                    </Box>
                                </Box>

                                <SelectInput
                                    source="nature"
                                    label="resources.packs.fields.nature"
                                    choices={natures}
                                    optionText="name"
                                    optionValue="id"
                                    fullWidth
                                />
                                <Box
                                    display={{
                                        xs: 'block',
                                        sm: 'flex',
                                    }}
                                >
                                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                        <NumberInput
                                            source="quantity"
                                            label="resources.packs.fields.quantity"
                                            resource="packs"
                                            // validate={[required()]}

                                            fullWidth
                                        />
                                    </Box>
                                    <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                        <NumberInput
                                            source="number"
                                            label="resources.packs.fields.number"
                                            resource="packs"
                                            // validate={[required()]}

                                            fullWidth
                                        />
                                    </Box>
                                </Box>
                                <Box
                                    display={{
                                        xs: 'block',
                                        sm: 'flex',
                                    }}
                                >
                                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                        <TextInput
                                            source="bl"
                                            label="resources.packs.fields.bl"
                                            resource="packs"
                                            // onChange={handleChange}
                                            validate={validateBl}
                                            disabled={permissions !== 'admin'}
                                            fullWidth
                                        />
                                    </Box>
                                    <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                        <DateInput
                                            source="date"
                                            label="resources.packs.fields.date"
                                            locales="fr-FR"
                                            // validate={requiredValidate}

                                            fullWidth
                                        />
                                    </Box>
                                </Box>

                                <Box
                                    display={{
                                        xs: 'block',
                                        sm: 'flex',
                                    }}
                                >
                                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                        <TextInput
                                            source="bl1"
                                            label="resources.packs.fields.bl1"
                                            resource="packs"
                                            // onChange={handleChange}
                                            validate={validateBl1}
                                            disabled={permissions !== 'admin'}
                                            fullWidth
                                        />
                                    </Box>
                                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                        <TextInput
                                            source="bl2"
                                            label="resources.packs.fields.bl2"
                                            resource="packs"
                                            // onChange={handleChange}
                                            validate={validateBl2}
                                            disabled={permissions !== 'admin'}
                                            fullWidth
                                        />
                                    </Box>
                                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                        <TextInput
                                            source="bl3"
                                            label="resources.packs.fields.bl3"
                                            resource="packs"
                                            // onChange={handleChange}
                                            validate={validateBl3}
                                            disabled={permissions !== 'admin'}
                                            fullWidth
                                        />
                                    </Box>
                                </Box>

                                <Box
                                    display={{
                                        xs: 'block',
                                        sm: 'flex',
                                    }}
                                >
                                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                        <TextInput
                                            source="grossiste"
                                            label="resources.packs.fields.grossiste"
                                            fullWidth
                                        />
                                    </Box>
                                    <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                        <NumberInput
                                            source="montantBonAchat"
                                            label="resources.packs.fields.montantBonAchat"
                                            fullWidth
                                        />
                                    </Box>
                                </Box>

                                <SelectInput
                                    source="status"
                                    label="resources.packs.fields.status"
                                    choices={[
                                        {
                                            id: 'new',
                                            name: 'Nouvelle',
                                        },
                                        {
                                            id: 'valid',
                                            name: 'Valide',
                                        },
                                        {
                                            id: 'rejected',
                                            name: 'Rejetée',
                                        },
                                    ]}
                                    validate={required()}
                                    optionText="name"
                                    optionValue="id"
                                    // disabled
                                    fullWidth
                                />
                                <SelectInput
                                    source="justificatifStatus"
                                    label="resources.packs.fields.justificatifStatus"
                                    choices={justificatifStatus}
                                    validate={required()}
                                    optionText="name"
                                    optionValue="id"
                                    // disabled
                                    fullWidth
                                />

<Box
                                    display={{
                                        xs: 'block',
                                        sm: 'flex',
                                    }}
                                >
                                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <PackJustificatifGrid
                                            record={props.record}
                                            label="resources.posts.fields.justificatif"
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </React.Fragment>
                </FormTab>
                {/* <FormTab label="Justificatif">
                    <React.Fragment>
                        <Box display={{ md: 'block', lg: 'flex' }}>
                            <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                <Box
                                    display={{
                                        xs: 'block',
                                        sm: 'flex',
                                    }}
                                >
                                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                        <SelectInput
                                            source="justificatifStatus"
                                            label="resources.packs.fields.justificatifStatus"
                                            choices={justificatifStatus}
                                            validate={required()}
                                            optionText="name"
                                            optionValue="id"
                                            // disabled
                                            fullWidth
                                        />
                                    </Box>
                                </Box>
                               <Box
                                    display={{
                                        xs: 'block',
                                        sm: 'flex',
                                    }}
                                >
                                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                        <PackJustificatifGrid
                                            record={props.record}
                                            label="resources.posts.fields.justificatif"
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </React.Fragment>
                </FormTab> */}
            </TabbedForm>
        </Card>
    );
};

// const requiredValidate = [required()];

export default PackEdit;
