import * as React from 'react';
import {
    FC,
    useState,
    useEffect,
    useCallback,
    Fragment,
    cloneElement,
} from 'react';
import {
    Datagrid,
    DateField,
    TextField,
    Filter,
    List,
    SearchInput,
    SelectField,
    ReferenceInput,
    SelectInput,
    DateInput,
    AutocompleteInput,
    NumberField,
    TopToolbar,
    ExportButton,
    // CreateButton,
    usePermissions,
} from 'react-admin';
import { Identifier } from 'ra-core';

import { Divider, Tabs, Tab, Box } from '@material-ui/core';

import { ListComponentProps, DatagridProps, Pack } from '../types';

import regions from '../data/regions';
import natures from '../data/natures';
import justificatifStatus from '../data/justificatifStatus';

// import ValidatePacksButton from './buttons/ValidatePacksButton';
// import RejectPacksButton from './buttons/RejectPacksButton';
// import RemovePacksButton from './buttons/RemovePacksButton';

const FileSaver = require('file-saver');

const exporter = (records, fetchRelatedRecords) => {
    const excelUrl = `${process.env.REACT_APP_ADMIN_REST_API_URL}/archive/excel`;
    FileSaver.saveAs(excelUrl, 'archive des demandes.xlsx');
};

const PackListActions = props => {
    const { permissions } = usePermissions();

    // if (permissions !== 'admin') {
    //     return null;
    // }
    return (
        <TopToolbar>
            {cloneElement(props.filters, { context: 'button' })}
            {/* <ValidatePacksButton />
        <RejectPacksButton />
        <RemovePacksButton />
       */}
            <ExportButton
                onClick={() => {
                    exporter([], []);
                }}
            />
        </TopToolbar>
    );
};
const PackFilter = (props: any) => (
    <Filter {...props}>
        <SearchInput source="q" resettable alwaysOn />
        <DateInput source="date" alwaysOn locales="fr-FR" />
        <ReferenceInput
            label="Commercial"
            source="created_by"
            reference="users"
            resettable
            alwaysOn
        >
            {/* <SelectInput optionText="name.full" /> */}
            <AutocompleteInput optionText="name.full" resettable />
        </ReferenceInput>
        <SelectInput
            source="region"
            label="resources.packs.fields.region"
            choices={regions}
            optionText="name"
            optionValue="id"
            alwaysOn
        />

        <SelectInput
            source="nature"
            label="resources.packs.fields.nature"
            choices={natures}
            optionText="name"
            optionValue="id"
            alwaysOn
        />
        <SelectInput
            source="status"
            label="resources.packs.fields.status"
            choices={[
                {
                    id: 'new',
                    name: 'Nouvelle',
                },
                {
                    id: 'valid',
                    name: 'Valide',
                },
                {
                    id: 'rejected',
                    name: 'Rejetée',
                },
            ]}
            optionText="name"
            optionValue="id"
            alwaysOn
        />
        <SelectInput
            source="justificatifStatus"
            label="resources.packs.fields.justificatifStatus"
            choices={justificatifStatus}
            optionText="name"
            optionValue="id"
            alwaysOn
        />
    </Filter>
);

const tabs = [
    // { id: 'basket', name: 'Panier' },
    { id: 'ELLAONE', name: 'ELLAONE' },
    { id: 'ACTICARBINE', name: 'ACTICARBINE' },
    { id: 'CLORACEF', name: 'CLORACEF' },
    { id: 'NALGESIC', name: 'NALGESIC' },
];

interface Props {
    ids: [string];
    filterValues: any;
    setFilters: any;
    displayedFilters: any;
}

interface TabbedDatagridProps extends DatagridProps<Pack> {}
const TabbedDatagrid: FC<TabbedDatagridProps> = ({
    ids,
    filterValues,
    setFilters,
    displayedFilters,
    ...rest
}) => {
    // products:['ELLAONE','ACTICARBINE','CLORACEF','NALGESIC']

    const [ELLAONE, setELLAONE] = useState<Identifier[]>([]);
    const [ACTICARBINE, setACTICARBINE] = useState<Identifier[]>([]);
    const [CLORACEF, setCLORACEF] = useState<Identifier[]>([]);
    const [NALGESIC, setNALGESIC] = useState<Identifier[]>([]);

    useEffect(() => {
        if (ids && ids !== filterValues.product) {
            switch (filterValues.product) {
                case 'ELLAONE':
                    setELLAONE(ids);
                    break;
                case 'ACTICARBINE':
                    setACTICARBINE(ids);
                    break;
                case 'CLORACEF':
                    setCLORACEF(ids);
                    break;
                case 'NALGESIC':
                    setNALGESIC(ids);
                    break;
            }
        }
    }, [ids, filterValues.product]);

    const handleChange = useCallback(
        (event: React.ChangeEvent<{}>, value: any) => {
            setFilters &&
                setFilters(
                    { ...filterValues, product: value },
                    displayedFilters
                );
        },
        [displayedFilters, filterValues, setFilters]
    );

    // const selectedIds =
    //     filterValues.product === 'ELLAONE'
    //         ? ELLAONE
    //         : filterValues.product === 'ACTICARBINE'
    //         ? ACTICARBINE
    //         : filterValues.product === 'CLORACEF'
    //         ? CLORACEF
    //         : filterValues.product === 'NALGESIC'
    //         ? NALGESIC
    //         : ELLAONE;

    return (
        <Fragment>
            <Tabs
                variant="fullWidth"
                centered
                value={filterValues.product}
                indicatorColor="primary"
                onChange={handleChange}
            >
                {tabs.map(choice => (
                    <Tab
                        key={choice.id}
                        label={choice.name}
                        value={choice.id}
                    />
                ))}
            </Tabs>
            <Divider />
            <div>
                {filterValues.product === 'ELLAONE' && (
                    <Datagrid {...rest} ids={ELLAONE} optimized rowClick="edit">
                        <TextField
                            source="created_by.name.full"
                            label="resources.packs.fields.created_by"
                        />
                        <TextField
                            source="pharmacy"
                            label="resources.packs.fields.pharmacy"
                        />
                        <TextField
                            source="city"
                            label="resources.packs.fields.city"
                        />

                        <SelectField
                            source="region"
                            label="resources.packs.fields.region"
                            choices={regions}
                            optionText="name"
                            optionValue="id"
                        />

                        <SelectField
                            source="nature"
                            label="resources.packs.fields.nature"
                            choices={[
                                {
                                    id: 'vm',
                                    name: 'VM',
                                },
                                {
                                    id: 'telephoniste',
                                    name: 'Téléphoniste',
                                },
                                {
                                    id: 'commande_directe',
                                    name: 'Commande Directe',
                                },
                            ]}
                            optionText="name"
                            optionValue="id"
                        />

                        <TextField
                            source="number"
                            label="resources.packs.fields.number"
                        />

                        <TextField
                            source="quantity"
                            label="resources.packs.fields.quantity"
                        />
                        <SelectField
                            source="status"
                            label="resources.packs.fields.status"
                            choices={[
                                {
                                    id: 'new',
                                    name: 'Nouvelle',
                                },
                                {
                                    id: 'valid',
                                    name: 'Valide',
                                },
                                {
                                    id: 'rejected',
                                    name: 'Rejetée',
                                },
                            ]}
                            optionText="name"
                            optionValue="id"
                        />
                        <TextField
                            source="bl"
                            label="resources.packs.fields.bl"
                        />

                        <DateField source="date" type="date" locales="fr-FR" />
                        <SelectField
                            source="justificatifStatus"
                            label="resources.packs.fields.justificatifStatus"
                            choices={justificatifStatus}
                            optionText="name"
                            optionValue="id"
                        />
                        <TextField
                            source="grossiste"
                            label="resources.packs.fields.grossiste"
                        />
                        <NumberField
                            source="montantBonAchat"
                            label="resources.packs.fields.montantBonAchat"
                        />
                    </Datagrid>
                )}
                {filterValues.product === 'ACTICARBINE' && (
                    <Datagrid
                        {...rest}
                        ids={ACTICARBINE}
                        optimized
                        rowClick="edit"
                    >
                        <TextField
                            source="created_by.name.full"
                            label="resources.packs.fields.created_by"
                        />
                        <TextField
                            source="pharmacy"
                            label="resources.packs.fields.pharmacy"
                        />
                        <TextField
                            source="city"
                            label="resources.packs.fields.city"
                        />

                        <SelectField
                            source="region"
                            label="resources.packs.fields.region"
                            choices={regions}
                            optionText="name"
                            optionValue="id"
                        />

                        <SelectField
                            source="nature"
                            label="resources.packs.fields.nature"
                            choices={[
                                {
                                    id: 'vm',
                                    name: 'VM',
                                },
                                {
                                    id: 'telephoniste',
                                    name: 'Téléphoniste',
                                },
                                {
                                    id: 'commande_directe',
                                    name: 'Commande Directe',
                                },
                            ]}
                            optionText="name"
                            optionValue="id"
                        />

                        <TextField
                            source="number"
                            label="resources.packs.fields.number"
                        />

                        <TextField
                            source="quantity"
                            label="resources.packs.fields.quantity"
                        />
                        <SelectField
                            source="status"
                            label="resources.packs.fields.status"
                            choices={[
                                {
                                    id: 'new',
                                    name: 'Nouvelle',
                                },
                                {
                                    id: 'valid',
                                    name: 'Valide',
                                },
                                {
                                    id: 'rejected',
                                    name: 'Rejetée',
                                },
                            ]}
                            optionText="name"
                            optionValue="id"
                        />
                        <TextField
                            source="bl"
                            label="resources.packs.fields.bl"
                        />

                        <DateField source="date" type="date" locales="fr-FR" />
                        <SelectField
                            source="justificatifStatus"
                            label="resources.packs.fields.justificatifStatus"
                            choices={justificatifStatus}
                            optionText="name"
                            optionValue="id"
                        />
                        <TextField
                            source="grossiste"
                            label="resources.packs.fields.grossiste"
                        />
                        <NumberField
                            source="montantBonAchat"
                            label="resources.packs.fields.montantBonAchat"
                        />
                    </Datagrid>
                )}
                {filterValues.product === 'CLORACEF' && (
                    <Datagrid
                        {...rest}
                        ids={CLORACEF}
                        optimized
                        rowClick="edit"
                    >
                        <TextField
                            source="created_by.name.full"
                            label="resources.packs.fields.created_by"
                        />
                        <TextField
                            source="pharmacy"
                            label="resources.packs.fields.pharmacy"
                        />
                        <TextField
                            source="city"
                            label="resources.packs.fields.city"
                        />

                        <SelectField
                            source="region"
                            label="resources.packs.fields.region"
                            choices={regions}
                            optionText="name"
                            optionValue="id"
                        />

                        <SelectField
                            source="nature"
                            label="resources.packs.fields.nature"
                            choices={[
                                {
                                    id: 'vm',
                                    name: 'VM',
                                },
                                {
                                    id: 'telephoniste',
                                    name: 'Téléphoniste',
                                },
                                {
                                    id: 'commande_directe',
                                    name: 'Commande Directe',
                                },
                            ]}
                            optionText="name"
                            optionValue="id"
                        />

                        <TextField
                            source="number"
                            label="resources.packs.fields.number"
                        />

                        <TextField
                            source="quantity"
                            label="resources.packs.fields.quantity"
                        />
                        <SelectField
                            source="status"
                            label="resources.packs.fields.status"
                            choices={[
                                {
                                    id: 'new',
                                    name: 'Nouvelle',
                                },
                                {
                                    id: 'valid',
                                    name: 'Valide',
                                },
                                {
                                    id: 'rejected',
                                    name: 'Rejetée',
                                },
                            ]}
                            optionText="name"
                            optionValue="id"
                        />
                        <TextField
                            source="bl"
                            label="resources.packs.fields.bl"
                        />

                        <DateField source="date" type="date" locales="fr-FR" />
                        <SelectField
                            source="justificatifStatus"
                            label="resources.packs.fields.justificatifStatus"
                            choices={justificatifStatus}
                            optionText="name"
                            optionValue="id"
                        />
                        <TextField
                            source="grossiste"
                            label="resources.packs.fields.grossiste"
                        />
                        <NumberField
                            source="montantBonAchat"
                            label="resources.packs.fields.montantBonAchat"
                        />
                    </Datagrid>
                )}
                {filterValues.product === 'NALGESIC' && (
                    <Datagrid
                        {...rest}
                        ids={NALGESIC}
                        optimized
                        rowClick="edit"
                    >
                        <TextField
                            source="created_by.name.full"
                            label="resources.packs.fields.created_by"
                        />
                        <TextField
                            source="pharmacy"
                            label="resources.packs.fields.pharmacy"
                        />
                        <TextField
                            source="city"
                            label="resources.packs.fields.city"
                        />

                        <SelectField
                            source="region"
                            label="resources.packs.fields.region"
                            choices={regions}
                            optionText="name"
                            optionValue="id"
                        />

                        <SelectField
                            source="nature"
                            label="resources.packs.fields.nature"
                            choices={[
                                {
                                    id: 'vm',
                                    name: 'VM',
                                },
                                {
                                    id: 'telephoniste',
                                    name: 'Téléphoniste',
                                },
                                {
                                    id: 'commande_directe',
                                    name: 'Commande Directe',
                                },
                            ]}
                            optionText="name"
                            optionValue="id"
                        />

                        <TextField
                            source="number"
                            label="resources.packs.fields.number"
                        />

                        <TextField
                            source="quantity"
                            label="resources.packs.fields.quantity"
                        />
                        <SelectField
                            source="status"
                            label="resources.packs.fields.status"
                            choices={[
                                {
                                    id: 'new',
                                    name: 'Nouvelle',
                                },
                                {
                                    id: 'valid',
                                    name: 'Valide',
                                },
                                {
                                    id: 'rejected',
                                    name: 'Rejetée',
                                },
                            ]}
                            optionText="name"
                            optionValue="id"
                        />
                        <TextField
                            source="bl"
                            label="resources.packs.fields.bl"
                        />

                        <DateField source="date" type="date" locales="fr-FR" />
                        <SelectField
                            source="justificatifStatus"
                            label="resources.packs.fields.justificatifStatus"
                            choices={justificatifStatus}
                            optionText="name"
                            optionValue="id"
                        />
                        <TextField
                            source="grossiste"
                            label="resources.packs.fields.grossiste"
                        />
                        <NumberField
                            source="montantBonAchat"
                            label="resources.packs.fields.montantBonAchat"
                        />
                    </Datagrid>
                )}
            </div>
        </Fragment>
    );
};

const PackList = (props: any) => {
    // const classes = useStyles();

    return (
        <List
            {...props}
            filter={{}}
            actions={<PackListActions />}
            filters={<PackFilter />}
            filterDefaultValues={{ product: 'ELLAONE' }}
            sort={{}}
            // perPage={25}
            bulkActionButtons={false}
            exporter={false}
            resource="archive"
            // aside={<PackListAside />}
        >
            <TabbedDatagrid />
        </List>
    );
};

export default PackList;
